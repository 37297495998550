exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-hello-world-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blog/hello-world/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-hello-world-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-my-second-post-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blog/my-second-post/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-my-second-post-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-new-beginnings-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blog/new-beginnings/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-new-beginnings-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-service-tecnology-stack-angular-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/data/service/tecnology-stack/angular.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-service-tecnology-stack-angular-mdx" */),
  "component---src-pages-candidate-tsx": () => import("./../../../src/pages/candidate.tsx" /* webpackChunkName: "component---src-pages-candidate-tsx" */),
  "component---src-pages-client-tsx": () => import("./../../../src/pages/client.tsx" /* webpackChunkName: "component---src-pages-client-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-model-tsx": () => import("./../../../src/pages/model.tsx" /* webpackChunkName: "component---src-pages-model-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-team-member-detail-tsx": () => import("./../../../src/templates/team-member-detail.tsx" /* webpackChunkName: "component---src-templates-team-member-detail-tsx" */)
}

